import axios from 'axios'

function addOffer(data) {
  return axios.post('offer', data)
}

function getOfferListing(payload) {
  return axios.get('offer/list', { params: payload })
}

function deleteOffer(id) {
  return axios.delete(`offer/${id}`)
}

function getOfferById(id) {
  return axios.get(`offer/${id}`)
}

function updateOffer(id, data) {
  return axios.put(`offer/${id}`, data)
}

function publishUnpublishOffer(id) {
  return axios.put(`offer/publish_unpublish/${id}`)
}

function getSavedTripDropDown() {
  return axios.get(`saved-trip/trip/without-offer`)
}

function duplicateOffer(id) {
  return axios.post(`offer/duplicate/${id}`)
}

export default {
  addOffer,
  getOfferListing,
  deleteOffer,
  getOfferById,
  updateOffer,
  publishUnpublishOffer,
  getSavedTripDropDown,
  duplicateOffer,
}
